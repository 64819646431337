// ICONS
import Home from '@mui/icons-material/Home';
import Info from '@mui/icons-material/Info';
import Storage from '@mui/icons-material/Storage';
import People from '@mui/icons-material/People';
import Widgets from '@mui/icons-material/Widgets';
import Business from '@mui/icons-material/Business';
import Contacts from '@mui/icons-material/Contacts';
import Message from '@mui/icons-material/Message';
import Email from '@mui/icons-material/Email';
import SimCard from '@mui/icons-material/SimCard';
import Dialpad from '@mui/icons-material/Dialpad';
import PhoneIphone from '@mui/icons-material/PhoneIphone';
import PieChart from '@mui/icons-material/PieChart';
import Assessment from '@mui/icons-material/Assessment';
import TableChart from '@mui/icons-material/TableChart';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocalOffer from '@mui/icons-material/LocalOffer';
import Event from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';
import ViewListIcon from '@mui/icons-material/ViewList';

import HomeView from "../views/HomeView";
import UsersView from "../views/UsersView/UsersView";
import AnalyticsView from "../views/AnalyticsView/AnalyticsView";
import DailyReportView from "../views/DailyReportView/DailyReportView";
import DailyReportView2 from "../views/DailyReportView2/DailyReportView2";
import DailyReportPrintView from "../views/DailyReportPrintView/DailyReportPrintView";
import ProductsView from "../views/ProductsView/ProductsView";
import LookupsView from "../views/LookupsView/LookupsView";
import EventsView from "../views/EventsView/EventsView";
import BookingsView from "../views/BookingsView/BookingsView";

import * as PERMISSIONS from './permissions';
import * as ROUTES from './routes';

export const VIEWS = [
    {
        label: "System",
        options: [
            {
                label: "Dashboard",
                path: "/",
                active: true,
                roles: PERMISSIONS.AllRoles,
                icon: <Home/>,
                element: <HomeView/>
            },
            {
                label: "Users",
                path: ROUTES.USERS,
                active: false,
                roles: PERMISSIONS.Users.Read,
                icon: <People/>,
                element: <UsersView/>
            }
        ],
    },
    {
        label: "Content",
        options: [
            {
                label: "Bookings",
                path: ROUTES.BOOKINGS,
                active: false,
                roles: PERMISSIONS.Bookings.Read,
                icon: <EventAvailableIcon/>,
                element: <BookingsView/>
            },
            {
                label: "Products",
                path: ROUTES.PRODUCTS,
                active: false,
                roles: PERMISSIONS.Products.Read,
                icon: <LocalOffer/>,
                element: <ProductsView/>
            },
            {
                label: "Lookups",
                path: ROUTES.LOOKUPS,
                active: false,
                roles: PERMISSIONS.Lookups.Read,
                icon: <ViewListIcon/>,
                element: <LookupsView/>
            },
            // OLD HELI CALENDAR VIEW
            // {
            //     label: "Calendar",
            //     path: ROUTES.CALENDAR,
            //     accounts: [1],
            //     active: false,
            //     roles: PERMISSIONS.Calendar.Read,
            //     icon: <Event/>,
            //     element: <CalendarView/>
            // },
            // NEW COMBINED CALENDAR, BASED ON EVENTS CALENDAR
            {
                label: "Calendar",
                path: ROUTES.CALENDAR,
                active: false,
                roles: PERMISSIONS.Calendar.Read,
                icon: <Event/>,
                element: <EventsView/>
            }
        ],
    },
    {
        label: "Reporting",
        options: [
            {
                label: "Analytics",
                path: ROUTES.ANALYTICS,
                roles: PERMISSIONS.AllRoles,
                icon: <Assessment/>,
                element: <AnalyticsView/>
            },
            {
                label: "Report",
                path: ROUTES.REPORT,
                roles: PERMISSIONS.AllRoles,
                icon: <TableChart/>,
                element: <DailyReportView/>
            },
            {
                label: "Report 2",
                path: ROUTES.REPORT2,
                roles: PERMISSIONS.AllRoles,
                icon: <TableChart/>,
                element: <DailyReportView2/>
            },
            {
                label: "Comms Report",
                path: ROUTES.REPORTPRINT,
                roles: PERMISSIONS.AllRoles,
                icon: <TableChart/>,
                element: <DailyReportPrintView/>
            }
        ]
    }
]

export const FORM_STATUS = {
    CREATE: "New Booking",
    VIEW: "View Booking",
    PAYMENT: "Payment",
    BOARD: "Boarding",
    OPTIONS: "Options",
    OPTIONS_EVENT: "Event Options",
    CREATE_EVENT: "Create Event",
    CREATE_PRIVATE_CHARTER: "Create Private Charter",
    VIEW_PRIVATE_CHARTER: "View Private Charter",
    PAYMENT_PRIVATE_CHARTER: "Payment Private Charter",
    BOARD_PRIVATE_CHARTER: "Boarding Private Charter",
    PASSENGER_MANIFEST: "Passenger Manifest"
}