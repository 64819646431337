// src/views/EventsView/BookingForm.js

import React from 'react';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';
import * as LOOKUPS from '../../constants/lookups.js';

import './BookingForm.scss';

import Datetime from '../../components/react-datetime/DateTime';
import "../../components/react-datetime/react-datetime.css";

import moment from "moment";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner'
import EventIcon from '@mui/icons-material/Event';
import axios from "axios";
import * as SETTINGS from "../../constants/settings";
import * as ALERTS from "../../constants/alerts";

export default class BookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      isLoaded: false,
      inputDisabled: false,
      data: {
      }
    };
  }

  handleChange = (e) => {
    e.persist();
    console.log(e);

    const val = e.target.value;
    const name = e.target.name;

    if(name === "route_id") {
      let event = {
        target: {
          value: LOOKUPS.ROUTES.filter((x) => x.id === parseInt(val))[0].val1,
          name: "route_range"
        }
      }
      console.log(event);
      this.props.handleChange(event);
    }
    if(name === "passengers") {
      let event = {
          target: {
              value: val * this.props.formData.seat_price,
              name: "price"
          }
      }
      console.log(event);
      this.props.handleChange(event);
    }
    if(name === "passengersKids") {
      let event = {
          target: {
              value: val * this.props.formData.seat_price_kids,
              name: "priceKids"
          }
      }
      console.log(event);
      this.props.handleChange(event);
    }

    if(name === "craft") {
      console.log(val);
      console.log(this.props.lookups.craft.filter((x) => x.id === val)[0]);
      let passengers = this.props.lookups.craft.filter((x) => x.id === val)[0].value4;
      let event = {
        target: {
          value: passengers,
          name: "passengers"
        }
      }
      this.props.handleChange(event);
    }

    this.props.handleChange(e);
  }

  goToPayment = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.props.payment();
    }
  }

  submitFormUpdate = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.props.updateBooking();
    }
  }

  render() {
    const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 }};
    let inputDisabled = this.state.inputDisabled ? true : false;
    let messageDialog = (<></>);
    let buttons = (<></>);
    let inputDisabledForm = inputDisabled;

    if(!PERM.Bookings.UpdateAfterClosed.includes(this.props.user.role) && this.props.formData.status_id === 2) {
      inputDisabled = true; 
      messageDialog = (<Form.Row><Form.Group as={Col}><div className="alert alert-primary" role="alert">Only Office Managers and Admin can update bookings after boarding.</div></Form.Group></Form.Row>);
    }

    if(inputDisabled) { buttons = (<></>); }
    else if (this.props.formData.id > 0) {
      buttons = (<>
        <button type="button" className="btn btn-primary btn-block btn-lg" onClick={this.submitFormUpdate}>Update</button>
        <button type="submit" className="btn btn-success btn-block btn-lg">Payment</button>
        <button type="button" className="btn btn-success btn-block btn-lg" onClick={this.props.goToBoard}>Board</button>
        <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={this.props.handleCancel}>Cancel</button></>);
    }
    else {
      buttons = (<>
        <button type="submit" className="btn btn-success btn-block btn-lg">Payment</button>
        <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={this.props.cancel}>Cancel</button></>);
    }

    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.goToPayment}>
      <Form.Row>
      <Form.Group as={Col} controlId="date">
          <Form.Label>Date and Time</Form.Label>
          <div className="input-group input-group-datetimepicker">
              <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><EventIcon/></span>
              </div>
              <input type="text" readonly="readonly" onfocus="blur();" pickerOptions={{allowInputToggle: false}} timeConstraints={constraints} name="date" value={this.props.formData.date} disabled={true} required/>
          </div>
      </Form.Group>
          <Form.Group as={Col} controlId="flightNumber">
              <Form.Label>Trip Number</Form.Label>
              <input
                  required
                  type="text"
                  className="form-control"
                  id="flightNumber"
                  name="flightNumber"
                  placeholder=""
                  value={this.props.formData.flightNumber}
                  disabled={true} />
          </Form.Group>
      </Form.Row>
      <div className="well">
      <Form.Row>
          <Form.Group className="mb-3 col-sm-2">
              <Form.Label>PAX Adult</Form.Label>
              <input
              type="number"
              className="form-control"
              min="1"
              max="100"
              id="passengers"
              name="passengers"
              value={this.props.formData.passengers}
              onChange={this.handleChange}
              required
              disabled={inputDisabled} />
          </Form.Group>
          <Form.Group as={Col} controlId="seat_price">
              <Form.Label>Seat Price</Form.Label>
                  <input
                      type="number"
                      className="form-control"
                      id="seat_price"
                      name="seat_price"
                      placeholder=""
                      value={this.props.formData.seat_price}
                      onChange={this.handleChange}
                      required
                      disabled={true} />
          </Form.Group>
          <Form.Group as={Col} controlId="price">
              <Form.Label>Price Total</Form.Label>
              <div className="input-group">
                  <div className="input-group-prepend">
                      <span className="input-group-text">R</span>
                  </div>
                  <input
                      type="number"
                      className="form-control"
                      id="price"
                      name="price"
                      placeholder=""
                      value={(this.props.formData.price)}
                      required
                      disabled={true} />
              </div>
          </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group className="mb-3 col-sm-2">
                  <Form.Label>PAX Kids</Form.Label>
                  <input
                      type="number"
                      className="form-control"
                      max="100"
                      id="passengersKids"
                      name="passengersKids"
                      placeholder=""
                      value={this.props.formData.passengersKids}
                      onChange={this.handleChange}
                      required
                      disabled={inputDisabled} />
              </Form.Group>
              <Form.Group as={Col} controlId="seat_price_kids">
                  <Form.Label>Seat Price</Form.Label>
                  <input
                      type="number"
                      className="form-control"
                      id="seat_price_kids"
                      name="seat_price_kids"
                      placeholder=""
                      value={this.props.formData.seat_price_kids}
                      onChange={this.handleChange}
                      required
                      disabled={true} />
              </Form.Group>
              <Form.Group as={Col} controlId="priceKids">
                  <Form.Label>Price Total</Form.Label>
                  <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text">R</span>
                      </div>
                      <input
                          type="number"
                          className="form-control"
                          id="priceKids"
                          name="priceKids"
                          placeholder=""
                          value={(this.props.formData.priceKids)}
                          required
                          disabled={true} />
                  </div>
              </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group className="mb-3 col-sm-2">
                  <Form.Label>PAX Toddlers</Form.Label>
                  <input
                      type="number"
                      className="form-control"
                      max="100"
                      id="passengersToddlers"
                      name="passengersToddlers"
                      placeholder=""
                      value={this.props.formData.passengersToddlers}
                      onChange={this.handleChange}
                      required
                      disabled={inputDisabled} />
              </Form.Group>
              <Form.Group as={Col} controlId="seat_price_toddlers">
                  <Form.Label>Seat Price</Form.Label>
                  <input
                      type="number"
                      className="form-control"
                      id="seat_price_toddlers"
                      name="seat_price_toddlers"
                      placeholder=""
                      value={0}
                      onChange={this.handleChange}
                      required
                      disabled={true} />
              </Form.Group>
              <Form.Group as={Col} controlId="priceToddlers">
                  <Form.Label>Price Total</Form.Label>
                  <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text">R</span>
                      </div>
                      <input
                          type="number"
                          className="form-control"
                          id="priceToddlers"
                          name="priceToddlers"
                          placeholder=""
                          value={0}
                          required
                          disabled={true} />
                  </div>
              </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group className="mb-3 col-sm-2">
                  <Form.Label>PAX Guides</Form.Label>
                  <input
                      type="number"
                      className="form-control"
                      max="100"
                      id="passengersGuides"
                      name="passengersGuides"
                      placeholder=""
                      value={this.props.formData.passengersGuides}
                      onChange={this.handleChange}
                      required
                      disabled={inputDisabled} />
              </Form.Group>
              <Form.Group as={Col} controlId="seat_price_guides">
                  <Form.Label>Seat Price</Form.Label>
                  <input
                      type="number"
                      className="form-control"
                      id="seat_price_guides"
                      name="seat_price_guides"
                      placeholder=""
                      value={0}
                      onChange={this.handleChange}
                      required
                      disabled={true} />
              </Form.Group>
              <Form.Group as={Col} controlId="priceGuides">
                  <Form.Label>Price Total</Form.Label>
                  <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text">R</span>
                      </div>
                      <input
                          type="number"
                          className="form-control"
                          id="priceGuides"
                          name="priceGuides"
                          placeholder=""
                          value={0}
                          required
                          disabled={true} />
                  </div>
              </Form.Group>
          </Form.Row>
      </div>
      <Form.Row>
      <Form.Group as={Col} controlId="route_id">
          <Form.Label>Route</Form.Label>
          <Select
          name="route_id"
          required="true"
          value={this.props.formData.route_id}
          options={LOOKUPS.ROUTES}
          onChange={this.handleChange}
          disabled={true}></Select>
      </Form.Group>
      <Form.Group as={Col} controlId="route_range">
          <Form.Label>Range</Form.Label>
          <input
          type="text"
          className="form-control"
          id="route_range"
          name="route_range"
          placeholder=""
          value={this.props.formData.route_range}
          onChange={this.handleChange}
          required
          disabled={true} />
      </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <input
          type="text"
          className="form-control"
          id="firstname"
          name="firstname"
          placeholder=""
          value={this.props.formData.firstname}
          onChange={this.handleChange}
          disabled={inputDisabled} />
      </Form.Group>
      <Form.Group as={Col} controlId="validationCustom02">
          <Form.Label>Last name</Form.Label>
          <input
          type="text"
          className="form-control"
          id="lastname"
          name="lastname"
          placeholder=""
          value={this.props.formData.lastname}
          onChange={this.handleChange}
          disabled={inputDisabled} />
      </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="phone">
          <Form.Label>Phone</Form.Label>
          <input
          type="tel"
          minLength="10"
          className="form-control"
          id="phone"
          name="phone"
          placeholder="+27713034000"
          value={this.props.formData.phone}
          onChange={this.handleChange}
          disabled={inputDisabled} />
          <Form.Control.Feedback type="invalid">
          Please provide a phone number.
          </Form.Control.Feedback>
      </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="email">
          <Form.Label>Email</Form.Label>
          <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          placeholder="you@example.com"
          value={this.props.formData.email}
          onChange={this.handleChange}
          disabled={inputDisabled} />
          <Form.Control.Feedback type="invalid">
          Please provide a valid email.
          </Form.Control.Feedback>
      </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="office_id">
          <Form.Label>Booking Office</Form.Label>
          <Select
            name="office_id"
            options={this.props.lookups.booking_offices}
            value={this.props.formData.office_id}
            onChange={this.handleChange}
            disabled={inputDisabled}></Select>
        </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="sales_agent_id">
            <Form.Label>Sales Agent</Form.Label>
            <Select
              name="sales_agent_id"
              options={this.props.lookups.agents_sales} // Data source for sales agents
              required={true} 
              value={this.props.formData.sales_agent_id} // Current value
              default="0" 
              showId={true} // Include ID in the display
              onChange={this.handleChange} // Handle changes
              disabled={inputDisabledForm} // Disabled state handling
            />
          </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="notes">
          <Form.Label>Booking Notes</Form.Label>
          <textarea 
          value={this.props.formData.notes ?? ""}
          onChange={this.handleChange}
          className="form-control"
          id="notes"
          name="notes"
          rows="4"
          disabled={inputDisabled}></textarea>
      </Form.Group>
      </Form.Row>
      <hr className="mb-4" />
      {messageDialog}
      {buttons}
      </Form>
    )
  }
}

const ACTIONS = {
  BOARD: "Board",
  BOOK: "Book",
  UPDATE: "Update"
}

function Submit(props) {

}

function Select(props) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const inputRef = React.useRef(null);

  // Check if props.default is set and props.value is empty, then set default value
  let value = props.value;
  if (props.default && !props.value) {
    value = props.default;
  }

  // Prepare lookup options
  let lookup = React.useMemo(() => {
    if (props.showId && props.options && props.options.length > 0) {
      return props.options.map((item) => ({
        id: item.id,
        name: `(${item.id}) ${item.name}`
      })).sort((a, b) => a.id - b.id);
    } else if (props.options && props.options.length > 0) {
      return props.options.sort((a, b) => a.id - b.id);
    }
    return [];
  }, [props.options, props.showId]);

  // Filter options based on search term
  const filteredOptions = React.useMemo(() => 
    lookup.filter(option => 
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [lookup, searchTerm]
  );

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleSelectOption = (option) => {
    const syntheticEvent = {
      target: {
        name: props.name,
        value: props.valueName ? option.name : option.id
      },
      persist: () => {} // Add a dummy persist method
    };
    props.onChange(syntheticEvent);
    setSearchTerm(option.name);
    setIsOpen(false);
  };

  React.useEffect(() => {
    const selectedOption = lookup.find(option => 
      option.id === value || option.name === value
    );
    if (selectedOption && document.activeElement !== inputRef.current) {
      setSearchTerm(selectedOption.name);
    } else if (!selectedOption && document.activeElement !== inputRef.current) {
      setSearchTerm('');
    }
  }, [value, lookup]);

  if (props.options) {
    return (
      <div className="custom-select-search">
        <input
          ref={inputRef}
          type="text"
          className="form-control"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setTimeout(() => setIsOpen(false), 200)}
          placeholder="Search..."
          disabled={props.disabled}
          required={props.required}
        />
        {isOpen && filteredOptions.length > 0 && (
          <ul className="dropdown-menu show">
            {filteredOptions.map((item, i) => (
              <li key={i} onMouseDown={() => handleSelectOption(item)}>
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  } else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

const formStatus = {
  CREATE: "New Booking",
  VIEW: "View Booking",
  PAYMENT: "Payment",
  BOARD: "Boarding"
}