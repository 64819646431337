// export const API_BASE_URL = 'http://192.168.0.131:8000/api';
// export const API_BASE_URL = 'https://api.sporthelicopters.co.za/api';
// export const API_BASE_URL = 'https://localhost:5001/api';

// export const API_BASE_URL = 'https://localhost:5001/api';
// export const API_BASE_URL_NOTIFICATION = 'https://localhost:5001/notificationService';

export const API_BASE_URL = 'https://sport-oms-api.azurewebsites.net/api';
export const API_BASE_URL_NOTIFICATION = 'https://sport-oms-api.azurewebsites.net/notificationService';

// export const VERSION = '1.3.220427';
// export const PRINT_SERVICE = 'http://local.printservice:6264';
// export const BOOKING_ID_OFFSET = 100000000;

// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const API_BASE_URL_NOTIFICATION = process.env.REACT_APP_API_BASE_URL_NOTIFICATION;

export const PRINT_SERVICE = 'http://local.printservice:6264';

export const BOOKING_ID_OFFSET = 100000000;
export const VERSION = '2.0.250121';

