// src/views/Calendar/BookingForm.js

import React from 'react';

import * as ROLES from '../../constants/roles.js';
import * as PERM from '../../constants/permissions.js';
import * as LOOKUPS from '../../constants/lookups.js';

import Datetime from '../../components/react-datetime/DateTime';
import "../../components/react-datetime/react-datetime.css";
import DataAccessLayer from '../../DAL';

import moment from "moment";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner'
import EventIcon from '@mui/icons-material/Event';
import * as SETTINGS from "../../constants/settings";
import * as ALERTS from "../../constants/alerts";

export default class BookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      isLoaded: false,
      inputDisabled: false,
      data: {
        booking_routes: [
          {"id":0,"name":"Other","val1":"0.3"},
          {"id":1,"name":"Hopper", "val1": "0.2"},
          {"id":2,"name":"Camps bay & Hout bay", "val1": "0.3"},
          {"id":3,"name":"Robbin Island", "val1": "0.3"},
          {"id":4,"name":"Robbin Island & Hopper", "val1": "0.5"},
          {"id":5,"name":"Two Oceans", "val1": "0.5"},
          {"id":6,"name":"Cape Point", "val1": "0.5"},
          {"id":7,"name":"Combat", "val1": "0.5"},
          {"id":8,"name":"Combat Lite", "val1": "0.3"},
          {"id":9,"name":"City Explorer", "val1": "0.1"},
          {"id":10,"name":"Full Peninsula", "val1": "1.0"},
          {"id":11,"name":"(PVT Charter) Clifton Shores", "val1": "2"},
          {"id":12,"name":"(PVT Charter) Sundowners", "val1": "2"},
          {"id":13,"name":"(PVT Charter) Sunset Cruise & Dine", "val1": "3"},
          {"id":14,"name":"(PVT Charter) Grand Clifton Experience", "val1": "3"},
          {"id":15,"name":"(PVT Charter) Half Day", "val1": "4"},
          {"id":16,"name":"(PVT Charter) Full Day", "val1": "8"},
          {"id":17,"name":"(PVT Charter) Sun & Sea Safari", "val1": "2"},
          {"id":22,"name":"(PVT Charter) 1 Hour", "val1": "1"}],
      }
    };
    this.DAL = new DataAccessLayer(this.props.token);
  }

  handleChange = (e) => {
    e.persist();
    console.log(e);

    const val = e.target.value;
    const name = e.target.name;

    if(name === "route_id") {
      let event = {
        target: {
          value: this.props.lookups.booking_routes.filter((x) => x.id === parseInt(val))[0].val1,
          name: "route_range"
        }
      }
      console.log(event);
      this.props.handleChange(event);
    }

    if(name === "commissionPercent") {
      let total = parseInt(this.props.formData.price) 
      + parseInt(this.props.formData.price_merchandise)
      + parseInt(this.props.formData.priceKids);
      let commission = total * (val / 100);
      let event = {
        target: {
          value: commission,
          name: "commission"
        }
      }
      console.log([this.props.formData.price, this.props.formData.price_merchandise, total, val, commission]);
      this.props.handleChange(event);
    }

    if(name === "price") {
      let total = parseInt(val)
      + parseInt(this.props.formData.price_merchandise)
      + parseInt(this.props.formData.priceKids);
      let commission = total * (this.props.formData.commissionPercent / 100);
      let event = {
        target: {
          value: commission,
          name: "commission"
        }
      }
      console.log([this.props.formData.price, this.props.formData.price_merchandise, total, val, commission]);
      this.props.handleChange(event);
    }

    if(name === "price_merchandise") {
      let total = parseInt(this.props.formData.price) 
      + parseInt(val)
      + parseInt(this.props.formData.priceKids);
      let commission = total * (this.props.formData.commissionPercent / 100);
      let event = {
        target: {
          value: commission,
          name: "commission"
        }
      }
      console.log([this.props.formData.price, this.props.formData.price_merchandise, total, val, commission]);
      this.props.handleChange(event);
    }

    if(name === "craft") {
      console.log(val);
      console.log(this.props.lookups.craft.filter((x) => x.id === val)[0]);
      let passengers = this.props.lookups.craft.filter((x) => x.id === val)[0].value4;
      let event = {
        target: {
          value: passengers,
          name: "passengers"
        }
      }
      this.props.handleChange(event);
    }

    this.props.handleChange(e);
  }

  goToPayment = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.props.payment();
    }
  }

  goToBoard = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.props.goToBoard();
    }
  }

  submitFormUpdate = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState(state => { state.validated = true; return state; });
    }
    else {
      this.props.updateBooking();
    }
  }

    submitForm = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState(state => { state.validated = true; return state; });
        }
        else {
            this.props.saveBooking();
        }
    }

    delete = (event) => {
        event.preventDefault();
        this.props.handleDeleteBooking(this.props.formData.id);
    }
    

  render() {
    const constraints = { hours: { min: 5, max: 20, step: 1 }, minutes: { step: 30 }};
    let inputDisabled = this.state.inputDisabled ? true : false;
    let messageDialog = (<></>);
    let buttons = (<></>);
    let messageHint = (<></>);
    let price = (<></>);
    let inputDisabledForm = inputDisabled;

    if(!PERM.Bookings.UpdateAfterClosed.includes(this.props.user.role) && this.props.formData.status_id === 2) {
      inputDisabled = true; 
      messageDialog = (<Form.Row><Form.Group as={Col}><div className="alert alert-primary" role="alert">Only Office Managers and Admin can update bookings after boarding.</div></Form.Group></Form.Row>);
    }

    if(this.props.formData.invoiced > 0) {
        inputDisabledForm = true;
    }

    const date = moment(this.props.formData.date);

    if(inputDisabled) { buttons = (<></>); }
    else if (this.props.formData.id > 0) {
      buttons = (<>
        <button type="button" className="btn btn-primary btn-block btn-lg" onClick={this.submitFormUpdate}>Update</button>
        <button type="button" className="btn btn-success btn-block btn-lg" onClick={this.goToPayment}>Payment</button>
        <button type="button" className="btn btn-success btn-block btn-lg" onClick={this.goToBoard}>Board</button>
        <button type="button" className="btn btn-danger btn-block btn-lg" onClick={this.delete}>Delete</button>
        <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={this.props.handleClose}>Cancel</button></>);
    }
    else {
        messageHint = (<Form.Row>
            <div className="alert alert-info" role="alert">
                Confirmation will send out an email with quoted payment amount to the client.
            </div>
        </Form.Row>);
      buttons = (<>
        <button type="submit" className="btn btn-primary btn-block btn-lg">Confirm</button>
        {/*<button type="submit" class="btn btn-success btn-block btn-lg">Payment</button>*/}
        <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={this.props.handleCancel}>Cancel</button></>);
      price = (<>
          <hr className="mb-4"/>
          <Form.Row>
              <Form.Group as={Col} controlId="price">
                  <Form.Label>Trip Price</Form.Label>
                  <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text">R</span>
                      </div>
                      <input
                          type="number"
                          className="form-control"
                          id="price"
                          name="price"
                          placeholder=""
                          value={this.props.formData.price}
                          onChange={this.handleChange}
                          required
                          disabled={inputDisabledForm}/>
                  </div>
              </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group as={Col} controlId="price_merchandise">
                  <Form.Label>Merchandise Price</Form.Label>
                  <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text">R</span>
                      </div>
                      <input
                          type="number"
                          className="form-control"
                          id="price_merchandise"
                          name="price_merchandise"
                          placeholder=""
                          value={this.props.formData.price_merchandise}
                          onChange={this.handleChange}
                          disabled={inputDisabledForm}/>
                  </div>
              </Form.Group>
          </Form.Row>
          <hr className="mb-4"/>
          <Form.Row>
            <Form.Group as={Col} controlId="commissionPercent">
              <Form.Label>Commission</Form.Label>
              <input type="number" max={100} min={0} className="form-control" id="commissionPercent" name="commissionPercent" placeholder="" value={this.props.formData.commissionPercent} onChange={this.handleChange} disabled={inputDisabledForm} required/>
              {/* <Select
              name="commissionPercent"
              required="true"
              valueName={false}
              value={this.props.formData.commissionPercent}
              options={[
                {"id": 0, "name": '0%'},
                {"id": 5, "name": '5%'},
                {"id": 10, "name": '10%'},
                {"id": 15, "name": '15%'},
                {"id": 20, "name": '20%'}
              ]}
              onChange={this.handleChange}
              disabled={inputDisabledForm}></Select> */}
            </Form.Group>
            <Form.Group as={Col} controlId="commission">
                  <Form.Label>&nbsp;</Form.Label>
                  <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text">R</span>
                      </div>
                      <input
                          type="number"
                          className="form-control"
                          id="commission"
                          name="commission"
                          placeholder=""
                          value={this.props.formData.commission}
                          onChange={this.handleChange}
                          disabled={true}/>
                  </div>
              </Form.Group>
          </Form.Row></>);
    }

    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.submitForm}>
      <Form.Row>
      <Form.Group as={Col} controlId="date">
          <Form.Label>Date and Time</Form.Label>
          <div className="input-group input-group-datetimepicker">
              <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><EventIcon/></span>
              </div>
              <Datetime readonly="readonly" onfocus="blur();" pickerOptions={{allowInputToggle: false}} timeConstraints={constraints} name="date" value={date} onChange={this.props.handleDateTime} disabled={true} required/>
          </div>
      </Form.Group>
      </Form.Row>
          <Form.Row>
              <Form.Group as={Col}>
                  <Form.Label>PAX Adult</Form.Label>
                  <select className="form-control"
                          id="passengers"
                          name="passengers"
                          placeholder=""
                          value={this.props.formData.passengers}
                          onChange={this.handleChange}
                          required
                          disabled={inputDisabled}>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>21</option>
                      <option>22</option>
                      <option>23</option>
                      <option>24</option>
                      <option>25</option>
                      <option>26</option>
                      <option>27</option>
                      <option>28</option>
                      <option>29</option>
                      <option>30</option>
                      <option>31</option>
                      <option>32</option>
                      <option>33</option>
                      <option>34</option>
                  </select>
              </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group as={Col}>
                  <Form.Label>PAX Kids</Form.Label>
                  <select className="form-control"
                          id="passengersKids"
                          name="passengersKids"
                          placeholder=""
                          value={this.props.formData.passengersKids}
                          onChange={this.handleChange}
                          required
                          disabled={inputDisabled}>
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>21</option>
                      <option>22</option>
                      <option>23</option>
                      <option>24</option>
                      <option>25</option>
                      <option>26</option>
                      <option>27</option>
                      <option>28</option>
                      <option>29</option>
                      <option>30</option>
                      <option>31</option>
                      <option>32</option>
                      <option>33</option>
                      <option>34</option>
                  </select>
              </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group as={Col}>
                  <Form.Label>PAX Toddlers</Form.Label>
                  <select className="form-control"
                          id="passengersToddlers"
                          name="passengersToddlers"
                          placeholder=""
                          value={this.props.formData.passengersToddlers}
                          onChange={this.handleChange}
                          required
                          disabled={inputDisabled}>
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>21</option>
                      <option>22</option>
                      <option>23</option>
                      <option>24</option>
                      <option>25</option>
                      <option>26</option>
                      <option>27</option>
                      <option>28</option>
                      <option>29</option>
                      <option>30</option>
                      <option>31</option>
                      <option>32</option>
                      <option>33</option>
                      <option>34</option>
                  </select>
              </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group as={Col}>
                  <Form.Label>PAX Guides</Form.Label>
                  <select className="form-control"
                          id="passengersGuides"
                          name="passengersGuides"
                          placeholder=""
                          value={this.props.formData.passengersGuides}
                          onChange={this.handleChange}
                          required
                          disabled={inputDisabled}>
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>21</option>
                      <option>22</option>
                      <option>23</option>
                      <option>24</option>
                      <option>25</option>
                      <option>26</option>
                      <option>27</option>
                      <option>28</option>
                      <option>29</option>
                      <option>30</option>
                      <option>31</option>
                      <option>32</option>
                      <option>33</option>
                      <option>34</option>
                  </select>
              </Form.Group>
          </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="route_id">
          <Form.Label>Route</Form.Label>
          <Select
          name="route_id"
          // options={this.state.data.booking_routes}
          required="true"
          valueName={false}
          value={this.props.formData.route_id}
          options={this.props.lookups.booking_routes}
          onChange={this.handleChange}
          disabled={inputDisabledForm}></Select>
      </Form.Group>
      <Form.Group as={Col} controlId="route_range">
          <Form.Label>Range</Form.Label>
          <input
          type="text"
          className="form-control"
          id="route_range"
          name="route_range"
          placeholder=""
          value={this.props.formData.route_range}
          onChange={this.handleChange}
          required
          disabled={inputDisabledForm} />
      </Form.Group>
      </Form.Row>
      <Form.Row>
          <Form.Group as={Col} controlId="craft">
              <Form.Label>Select Craft</Form.Label>
              <Select
                  name="craft"
                  options={this.props.lookups.crafts}
                  required={false}
                  valueName={true}
                  value={this.props.formData.craft}
                  onChange={this.props.handleChange}
                  disabled={inputDisabled}></Select>
          </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <input
          type="text"
          className="form-control"
          id="firstname"
          name="firstname"
          placeholder=""
          value={this.props.formData.firstname}
          onChange={this.handleChange}
          required
          disabled={inputDisabledForm} />
      </Form.Group>
      <Form.Group as={Col} controlId="validationCustom02">
          <Form.Label>Last name</Form.Label>
          <input
          type="text"
          className="form-control"
          id="lastname"
          name="lastname"
          placeholder=""
          value={this.props.formData.lastname}
          onChange={this.handleChange}
          required
          disabled={inputDisabledForm} />
      </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="phone">
          <Form.Label>Phone</Form.Label>
          <input
          type="tel"
          minLength="10"
          className="form-control"
          id="phone"
          name="phone"
          placeholder="+27713034000"
          value={this.props.formData.phone}
          onChange={this.handleChange}
          disabled={inputDisabledForm} />
          <Form.Control.Feedback type="invalid">
          Please provide a phone number.
          </Form.Control.Feedback>
      </Form.Group>
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="email">
          <Form.Label>Email</Form.Label>
          <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          placeholder="you@example.com"
          value={this.props.formData.email}
          onChange={this.handleChange}
          required
          disabled={inputDisabled} />
          <Form.Control.Feedback type="invalid">
          Please provide a valid email.
          </Form.Control.Feedback>
      </Form.Group>
      </Form.Row>
      <Form.Row>
          <Form.Group as={Col} controlId="office_id">
            <Form.Label>Booking Office</Form.Label>
            <Select
              name="office_id"
              options={this.props.lookups.booking_offices}
              required="true"
              valueName={false}
              value={this.props.formData.office_id}
              onChange={this.handleChange}
              disabled={inputDisabledForm}></Select>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="sales_agent_id">
            <Form.Label>Sales Agent</Form.Label>
              <Select
                name="sales_agent_id"
                options={this.props.lookups.agents_sales}
                required="true"
                value={this.props.formData.sales_agent_id}
                default="0"
                showId="true"
                onChange={this.handleChange}
                disabled={inputDisabledForm}></Select>
          </Form.Group>
        </Form.Row>
      <Form.Row>
      <Form.Group as={Col} controlId="notes">
          <Form.Label>Booking Notes</Form.Label>
          <textarea 
          value={this.props.formData.notes ?? ""}
          onChange={this.handleChange}
          className="form-control"
          id="notes"
          name="notes"
          rows="4"
          disabled={inputDisabled}></textarea>
      </Form.Group>
      </Form.Row>
      {price}
      <hr className="mb-4" />
      {messageDialog}
      {messageHint}
      {buttons}
      </Form>
    )
  }
}

const ACTIONS = {
  BOARD: "Board",
  BOOK: "Book",
  UPDATE: "Update"
}

function Submit(props) {

}

function Select(props) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const inputRef = React.useRef(null);

  // Check if props.default is set and props.value is empty, then set default value
  let value = props.value;
  if (props.default && !props.value) {
    value = props.default;
  }

  // Prepare lookup options
  let lookup = React.useMemo(() => {
    if (props.showId && props.options && props.options.length > 0) {
      return props.options.map((item) => ({
        id: item.id,
        name: `(${item.id}) ${item.name}`
      })).sort((a, b) => a.id - b.id);
    } else if (props.options && props.options.length > 0) {
      return props.options.sort((a, b) => a.id - b.id);
    }
    return [];
  }, [props.options, props.showId]);

  // Filter options based on search term
  const filteredOptions = React.useMemo(() => 
    lookup.filter(option => 
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [lookup, searchTerm]
  );

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleSelectOption = (option) => {
    const syntheticEvent = {
      target: {
        name: props.name,
        value: props.valueName ? option.name : option.id
      },
      persist: () => {} // Add a dummy persist method
    };
    props.onChange(syntheticEvent);
    setSearchTerm(option.name);
    setIsOpen(false);
  };

  React.useEffect(() => {
    const selectedOption = lookup.find(option => 
      option.id === value || option.name === value
    );
    if (selectedOption && document.activeElement !== inputRef.current) {
      setSearchTerm(selectedOption.name);
    } else if (!selectedOption && document.activeElement !== inputRef.current) {
      setSearchTerm('');
    }
  }, [value, lookup]);

  if (props.options) {
    return (
      <div className="custom-select-search">
        <input
          ref={inputRef}
          type="text"
          className="form-control"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setTimeout(() => setIsOpen(false), 200)}
          placeholder="Search..."
          disabled={props.disabled}
          required={props.required}
        />
        {isOpen && filteredOptions.length > 0 && (
          <ul className="dropdown-menu show">
            {filteredOptions.map((item, i) => (
              <li key={i} onMouseDown={() => handleSelectOption(item)}>
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  } else {
    return (
      <div className="custom-select d-block w-100">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

const formStatus = {
  CREATE: "New Booking",
  VIEW: "View Booking",
  PAYMENT: "Payment",
  BOARD: "Boarding"
}

